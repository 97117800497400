.ovr-1 {
  background-image: radial-gradient(
      circle at 79% 30%,
      rgba(230, 230, 230, 0.04) 0%,
      rgba(230, 230, 230, 0.04) 50%,
      rgba(12, 12, 12, 0.04) 50%,
      rgba(12, 12, 12, 0.04) 100%
    ),
    radial-gradient(
      circle at 53% 89%,
      rgba(210, 210, 210, 0.04) 0%,
      rgba(210, 210, 210, 0.04) 50%,
      rgba(24, 24, 24, 0.04) 50%,
      rgba(24, 24, 24, 0.04) 100%
    ),
    radial-gradient(
      circle at 92% 89%,
      rgba(17, 17, 17, 0.04) 0%,
      rgba(17, 17, 17, 0.04) 50%,
      rgba(205, 205, 205, 0.04) 50%,
      rgba(205, 205, 205, 0.04) 100%
    ),
    radial-gradient(
      circle at 21% 13%,
      rgba(124, 124, 124, 0.04) 0%,
      rgba(124, 124, 124, 0.04) 50%,
      rgba(243, 243, 243, 0.04) 50%,
      rgba(243, 243, 243, 0.04) 100%
    ),
    radial-gradient(
      circle at 77% 0%,
      rgba(16, 16, 16, 0.04) 0%,
      rgba(16, 16, 16, 0.04) 50%,
      rgba(130, 130, 130, 0.04) 50%,
      rgba(130, 130, 130, 0.04) 100%
    ),
    linear-gradient(90deg, rgb(247, 101, 31), rgb(249, 105, 208));
}
.ovr-2 {
  background-image: radial-gradient(
      circle at 52% 94%,
      rgba(169, 169, 169, 0.04) 0%,
      rgba(169, 169, 169, 0.04) 50%,
      rgba(199, 199, 199, 0.04) 50%,
      rgba(199, 199, 199, 0.04) 100%
    ),
    radial-gradient(
      circle at 96% 98%,
      rgba(61, 61, 61, 0.04) 0%,
      rgba(61, 61, 61, 0.04) 50%,
      rgba(201, 201, 201, 0.04) 50%,
      rgba(201, 201, 201, 0.04) 100%
    ),
    radial-gradient(
      circle at 93% 97%,
      rgba(227, 227, 227, 0.04) 0%,
      rgba(227, 227, 227, 0.04) 50%,
      rgba(145, 145, 145, 0.04) 50%,
      rgba(145, 145, 145, 0.04) 100%
    ),
    radial-gradient(
      circle at 79% 52%,
      rgba(245, 245, 245, 0.04) 0%,
      rgba(245, 245, 245, 0.04) 50%,
      rgba(86, 86, 86, 0.04) 50%,
      rgba(86, 86, 86, 0.04) 100%
    ),
    linear-gradient(90deg, rgb(210, 9, 198), rgb(25, 38, 118));
}
.ovr-3 {
  background-image: radial-gradient(
      circle at 17% 1%,
      rgba(198, 198, 198, 0.03) 0%,
      rgba(198, 198, 198, 0.03) 50%,
      rgba(42, 42, 42, 0.03) 50%,
      rgba(42, 42, 42, 0.03) 100%
    ),
    radial-gradient(
      circle at 8% 81%,
      rgba(253, 253, 253, 0.03) 0%,
      rgba(253, 253, 253, 0.03) 50%,
      rgba(36, 36, 36, 0.03) 50%,
      rgba(36, 36, 36, 0.03) 100%
    ),
    radial-gradient(
      circle at 83% 29%,
      rgba(164, 164, 164, 0.03) 0%,
      rgba(164, 164, 164, 0.03) 50%,
      rgba(60, 60, 60, 0.03) 50%,
      rgba(60, 60, 60, 0.03) 100%
    ),
    radial-gradient(
      circle at 96% 62%,
      rgba(170, 170, 170, 0.03) 0%,
      rgba(170, 170, 170, 0.03) 50%,
      rgba(169, 169, 169, 0.03) 50%,
      rgba(169, 169, 169, 0.03) 100%
    ),
    linear-gradient(338deg, rgb(2, 141, 213), rgb(5, 172, 81));
}
.ovr-4 {
  background-image: radial-gradient(
      circle at 17% 77%,
      rgba(17, 17, 17, 0.04) 0%,
      rgba(17, 17, 17, 0.04) 50%,
      rgba(197, 197, 197, 0.04) 50%,
      rgba(197, 197, 197, 0.04) 100%
    ),
    radial-gradient(
      circle at 26% 17%,
      rgba(64, 64, 64, 0.04) 0%,
      rgba(64, 64, 64, 0.04) 50%,
      rgba(244, 244, 244, 0.04) 50%,
      rgba(244, 244, 244, 0.04) 100%
    ),
    radial-gradient(
      circle at 44% 60%,
      rgba(177, 177, 177, 0.04) 0%,
      rgba(177, 177, 177, 0.04) 50%,
      rgba(187, 187, 187, 0.04) 50%,
      rgba(187, 187, 187, 0.04) 100%
    ),
    linear-gradient(19deg, rgb(28, 117, 250), rgb(34, 2, 159));
}
.ovr-5 {
  background-image: radial-gradient(
      circle at 72% 83%,
      rgba(12, 12, 12, 0.04) 0%,
      rgba(12, 12, 12, 0.04) 50%,
      rgba(172, 172, 172, 0.04) 50%,
      rgba(172, 172, 172, 0.04) 100%
    ),
    radial-gradient(
      circle at 57% 65%,
      rgba(11, 11, 11, 0.04) 0%,
      rgba(11, 11, 11, 0.04) 50%,
      rgba(222, 222, 222, 0.04) 50%,
      rgba(222, 222, 222, 0.04) 100%
    ),
    radial-gradient(
      circle at 64% 31%,
      rgba(11, 11, 11, 0.04) 0%,
      rgba(11, 11, 11, 0.04) 50%,
      rgba(191, 191, 191, 0.04) 50%,
      rgba(191, 191, 191, 0.04) 100%
    ),
    linear-gradient(130deg, rgb(5, 235, 123), rgb(47, 52, 6));
}
.ovr-6 {
  background-image: radial-gradient(
      circle at 21% 44%,
      rgba(23, 23, 23, 0.05) 0%,
      rgba(23, 23, 23, 0.05) 50%,
      rgba(109, 109, 109, 0.05) 50%,
      rgba(109, 109, 109, 0.05) 100%
    ),
    radial-gradient(
      circle at 21% 96%,
      rgba(92, 92, 92, 0.05) 0%,
      rgba(92, 92, 92, 0.05) 50%,
      rgba(199, 199, 199, 0.05) 50%,
      rgba(199, 199, 199, 0.05) 100%
    ),
    radial-gradient(
      circle at 25% 37%,
      rgba(230, 230, 230, 0.05) 0%,
      rgba(230, 230, 230, 0.05) 50%,
      rgba(25, 25, 25, 0.05) 50%,
      rgba(25, 25, 25, 0.05) 100%
    ),
    linear-gradient(90deg, rgb(156, 48, 160), rgb(75, 105, 255));
}
.ovr-7 {
  background-image: radial-gradient(
      circle at 85% 1%,
      hsla(190, 0%, 93%, 0.05) 0%,
      hsla(190, 0%, 93%, 0.05) 96%,
      transparent 96%,
      transparent 100%
    ),
    radial-gradient(
      circle at 14% 15%,
      hsla(190, 0%, 93%, 0.05) 0%,
      hsla(190, 0%, 93%, 0.05) 1%,
      transparent 1%,
      transparent 100%
    ),
    radial-gradient(
      circle at 60% 90%,
      hsla(190, 0%, 93%, 0.05) 0%,
      hsla(190, 0%, 93%, 0.05) 20%,
      transparent 20%,
      transparent 100%
    ),
    radial-gradient(
      circle at 79% 7%,
      hsla(190, 0%, 93%, 0.05) 0%,
      hsla(190, 0%, 93%, 0.05) 78%,
      transparent 78%,
      transparent 100%
    ),
    radial-gradient(
      circle at 55% 65%,
      hsla(190, 0%, 93%, 0.05) 0%,
      hsla(190, 0%, 93%, 0.05) 52%,
      transparent 52%,
      transparent 100%
    ),
    linear-gradient(135deg, rgb(37, 56, 222), rgb(96, 189, 244));
}

.ovr-8 {
  background-image: linear-gradient(
      306deg,
      rgba(54, 54, 54, 0.05) 0%,
      rgba(54, 54, 54, 0.05) 33.333%,
      rgba(85, 85, 85, 0.05) 33.333%,
      rgba(85, 85, 85, 0.05) 66.666%,
      rgba(255, 255, 255, 0.05) 66.666%,
      rgba(255, 255, 255, 0.05) 99.999%
    ),
    linear-gradient(
      353deg,
      rgba(81, 81, 81, 0.05) 0%,
      rgba(81, 81, 81, 0.05) 33.333%,
      rgba(238, 238, 238, 0.05) 33.333%,
      rgba(238, 238, 238, 0.05) 66.666%,
      rgba(32, 32, 32, 0.05) 66.666%,
      rgba(32, 32, 32, 0.05) 99.999%
    ),
    linear-gradient(
      140deg,
      rgba(192, 192, 192, 0.05) 0%,
      rgba(192, 192, 192, 0.05) 33.333%,
      rgba(109, 109, 109, 0.05) 33.333%,
      rgba(109, 109, 109, 0.05) 66.666%,
      rgba(30, 30, 30, 0.05) 66.666%,
      rgba(30, 30, 30, 0.05) 99.999%
    ),
    linear-gradient(
      189deg,
      rgba(77, 77, 77, 0.05) 0%,
      rgba(77, 77, 77, 0.05) 33.333%,
      rgba(55, 55, 55, 0.05) 33.333%,
      rgba(55, 55, 55, 0.05) 66.666%,
      rgba(145, 145, 145, 0.05) 66.666%,
      rgba(145, 145, 145, 0.05) 99.999%
    ),
    linear-gradient(90deg, rgb(9, 201, 186), rgb(18, 131, 221));
}
.ovr-9 {
  background-image: linear-gradient(
      131deg,
      rgba(186, 186, 186, 0.01) 0%,
      rgba(186, 186, 186, 0.01) 16.667%,
      rgba(192, 192, 192, 0.01) 16.667%,
      rgba(192, 192, 192, 0.01) 33.334%,
      rgba(48, 48, 48, 0.01) 33.334%,
      rgba(48, 48, 48, 0.01) 50.001000000000005%,
      rgba(33, 33, 33, 0.01) 50.001%,
      rgba(33, 33, 33, 0.01) 66.668%,
      rgba(182, 182, 182, 0.01) 66.668%,
      rgba(182, 182, 182, 0.01) 83.33500000000001%,
      rgba(211, 211, 211, 0.01) 83.335%,
      rgba(211, 211, 211, 0.01) 100.002%
    ),
    linear-gradient(
      148deg,
      rgba(48, 48, 48, 0.01) 0%,
      rgba(48, 48, 48, 0.01) 16.667%,
      rgba(178, 178, 178, 0.01) 16.667%,
      rgba(178, 178, 178, 0.01) 33.334%,
      rgba(192, 192, 192, 0.01) 33.334%,
      rgba(192, 192, 192, 0.01) 50.001000000000005%,
      rgba(237, 237, 237, 0.01) 50.001%,
      rgba(237, 237, 237, 0.01) 66.668%,
      rgba(194, 194, 194, 0.01) 66.668%,
      rgba(194, 194, 194, 0.01) 83.33500000000001%,
      rgba(227, 227, 227, 0.01) 83.335%,
      rgba(227, 227, 227, 0.01) 100.002%
    ),
    linear-gradient(
      138deg,
      rgba(146, 146, 146, 0.03) 0%,
      rgba(146, 146, 146, 0.03) 25%,
      rgba(33, 33, 33, 0.03) 25%,
      rgba(33, 33, 33, 0.03) 50%,
      rgba(46, 46, 46, 0.03) 50%,
      rgba(46, 46, 46, 0.03) 75%,
      rgba(172, 172, 172, 0.03) 75%,
      rgba(172, 172, 172, 0.03) 100%
    ),
    linear-gradient(
      38deg,
      rgba(3, 3, 3, 0.03) 0%,
      rgba(3, 3, 3, 0.03) 16.667%,
      rgba(28, 28, 28, 0.03) 16.667%,
      rgba(28, 28, 28, 0.03) 33.334%,
      rgba(236, 236, 236, 0.03) 33.334%,
      rgba(236, 236, 236, 0.03) 50.001000000000005%,
      rgba(3, 3, 3, 0.03) 50.001%,
      rgba(3, 3, 3, 0.03) 66.668%,
      rgba(207, 207, 207, 0.03) 66.668%,
      rgba(207, 207, 207, 0.03) 83.33500000000001%,
      rgba(183, 183, 183, 0.03) 83.335%,
      rgba(183, 183, 183, 0.03) 100.002%
    ),
    linear-gradient(
      145deg,
      rgba(20, 20, 20, 0.02) 0%,
      rgba(20, 20, 20, 0.02) 20%,
      rgba(4, 4, 4, 0.02) 20%,
      rgba(4, 4, 4, 0.02) 40%,
      rgba(194, 194, 194, 0.02) 40%,
      rgba(194, 194, 194, 0.02) 60%,
      rgba(34, 34, 34, 0.02) 60%,
      rgba(34, 34, 34, 0.02) 80%,
      rgba(71, 71, 71, 0.02) 80%,
      rgba(71, 71, 71, 0.02) 100%
    ),
    linear-gradient(
      78deg,
      rgba(190, 190, 190, 0.02) 0%,
      rgba(190, 190, 190, 0.02) 20%,
      rgba(95, 95, 95, 0.02) 20%,
      rgba(95, 95, 95, 0.02) 40%,
      rgba(71, 71, 71, 0.02) 40%,
      rgba(71, 71, 71, 0.02) 60%,
      rgba(7, 7, 7, 0.02) 60%,
      rgba(7, 7, 7, 0.02) 80%,
      rgba(130, 130, 130, 0.02) 80%,
      rgba(130, 130, 130, 0.02) 100%
    ),
    linear-gradient(
      293deg,
      rgba(213, 213, 213, 0.03) 0%,
      rgba(213, 213, 213, 0.03) 20%,
      rgba(220, 220, 220, 0.03) 20%,
      rgba(220, 220, 220, 0.03) 40%,
      rgba(146, 146, 146, 0.03) 40%,
      rgba(146, 146, 146, 0.03) 60%,
      rgba(57, 57, 57, 0.03) 60%,
      rgba(57, 57, 57, 0.03) 80%,
      rgba(120, 120, 120, 0.03) 80%,
      rgba(120, 120, 120, 0.03) 100%
    ),
    linear-gradient(90deg, rgb(225, 15, 180), rgb(78, 198, 243));
}

.ovr-10 {
  background-image: linear-gradient(
      161deg,
      rgba(117, 117, 117, 0.03) 0%,
      rgba(117, 117, 117, 0.03) 50%,
      rgba(8, 8, 8, 0.03) 50%,
      rgba(8, 8, 8, 0.03) 100%
    ),
    linear-gradient(
      59deg,
      rgba(245, 245, 245, 0.05) 0%,
      rgba(245, 245, 245, 0.05) 50%,
      rgba(68, 68, 68, 0.05) 50%,
      rgba(68, 68, 68, 0.05) 100%
    ),
    linear-gradient(
      286deg,
      rgba(107, 107, 107, 0.07) 0%,
      rgba(107, 107, 107, 0.07) 50%,
      rgba(7, 7, 7, 0.07) 50%,
      rgba(7, 7, 7, 0.07) 100%
    ),
    linear-gradient(
      123deg,
      rgba(9, 9, 9, 0.09) 0%,
      rgba(9, 9, 9, 0.09) 50%,
      rgba(120, 120, 120, 0.09) 50%,
      rgba(120, 120, 120, 0.09) 100%
    ),
    linear-gradient(
      170deg,
      rgba(202, 202, 202, 0.01) 0%,
      rgba(202, 202, 202, 0.01) 50%,
      rgba(19, 19, 19, 0.01) 50%,
      rgba(19, 19, 19, 0.01) 100%
    ),
    linear-gradient(
      210deg,
      rgba(64, 64, 64, 0.04) 0%,
      rgba(64, 64, 64, 0.04) 50%,
      rgba(4, 4, 4, 0.04) 50%,
      rgba(4, 4, 4, 0.04) 100%
    ),
    linear-gradient(90deg, rgb(249, 17, 190), rgb(100, 28, 213));
}

.text-gold {
  color: #f1b500;
}
.brd-gradient {
  border-width: 1px;
  border-style: solid;
  border-image: linear-gradient(to right, rgb(139, 53, 0), rgb(154, 11, 226)) 1;
}
.btn-costom-df {
  background-color: #060622 !important;
  border: 1px solid grey !important;
}

.gradient-border-pseudo {
  background: linear-gradient(90deg, rgb(255, 0, 187), rgb(117, 31, 255)) padding-box,
    linear-gradient(to bottom, #e44d26, #bc1dd6) border-box;
  border: 4px solid transparent;
  border-radius: 5px;
}
