.page_footer_con a:hover {
  color: #328f18;
}
.page_footer_con .foter_copyright {
  color: #000000;
  text-align: center;
  font-weight: 500 !important;
}

.page_footer_con img {
  width: 120px !important;
}
.page_footer_con {
  padding: 4rem 0;
  background-image: linear-gradient(
      135deg,
      rgba(181, 209, 42, 0.5) 0%,
      rgba(181, 209, 42, 0.5) 16%,
      rgba(38, 179, 43, 0.5) 16%,
      rgba(38, 179, 43, 0.5) 49%,
      transparent 49%,
      transparent 96%,
      rgba(252, 224, 41, 0.5) 96%,
      rgba(227, 200, 78, 0.5) 100%
    ),
    linear-gradient(
      45deg,
      rgb(181, 209, 42) 0%,
      rgb(181, 209, 42) 8%,
      rgb(38, 179, 43) 8%,
      rgb(38, 179, 43) 31%,
      transparent 31%,
      transparent 45%,
      rgb(255, 255, 255) 45%,
      rgb(255, 255, 255) 100%
    ),
    linear-gradient(90deg, rgb(255, 255, 255), rgb(255, 255, 255));
  font-size: 15px;
  color: #000000;
  border-top-left-radius: 100px;
  border-top: 1px solid rgb(217, 216, 216);
  border-top-right-radius: 100px;
}
.page_footer_con .page_footer_con-heading > span {
  font-weight: 900 !important;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}
.page_footer_con .page_footer_con-heading {
  font-size: 16px;
  color: #000000;
  margin-bottom: 30px;
  text-transform: uppercase;
}

.page_footer_con ul li {
  margin-bottom: 10px;
  font-weight: 500 !important;
}
.page_footer_con a {
  color: #000000;
  text-decoration: none;
}
