.emp-profile {
  padding: 3%;
  margin-top: 3%;
  margin-bottom: 3%;
  border-radius: 0.5rem;
  background: #01e1b087 !important;
  color: white;
  border: 2px solid rgb(241, 91, 91) !important;
}
.profile-img {
  text-align: center;
}

.profile-img .file {
  position: relative;
  overflow: hidden;
  margin-top: -20%;
  width: 70%;
  border: none;
  border-radius: 0;
  font-size: 15px;
  background: #212529b8;
}
.profile-img .file input {
  position: absolute;
  opacity: 0;
  right: 0;
  top: 0;
}
.profile-head h5 {
  color: #ffffff;
}
.profile-head h6 {
  color: #d4d4d4;
}
.profile-edit-btn {
  border: none;
  border-radius: 1.5rem;
  width: 70%;
  padding: 2%;
  font-weight: 600;
  color: #000000;
  cursor: pointer;
}
.proile-rating {
  font-size: 14px;
  color: #d4d4d4;
  text-decoration: underline;
  margin-top: 2px;
  margin-bottom: 3px;
}
.proile-rating span {
  color: #b1b1b1;
  font-size: 15px;
  font-weight: 600;
}
/* .profile-head .nav-tabs {
  margin-bottom: 1%;
} */
.profile-head .nav-tabs .nav-link {
  font-weight: 600;
  border: none;
}
.profile-head .nav-tabs .nav-link.active {
  border: none;
  border-bottom: 2px solid orange;
}
.profile-work {
  padding: 14%;
  margin-top: -15%;
}
.profile-work p {
  font-size: 12px;
  color: #fdfdfd;
  font-weight: 600;
  margin-top: 10%;
}
.profile-work a {
  text-decoration: none;
  color: #dbedff;
  font-weight: 600;
  font-size: 14px;
}
.profile-work ul {
  list-style: none;
}
.profile-tab label {
  font-weight: 600;
}
.profile-tab p {
  font-weight: 600;
  color: #cfcbff;
}
.active-status {
  background: rgb(0, 174, 0);
  width: fit-content;
  padding: 2px;
  border-radius: 3px;
  font-size: 14px;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}
.changepro{
  margin: 0 auto !important;
  width: fit-content;
}
.uploadpro{
  margin: 0 auto !important;
}