/* Mobile menu styles */
.root-mobile-menu.open {
  transform: translateX(0%);
}
.mobile-nav-link {
  color: #fff;
  text-decoration: none;
  font-size: 1em;
  padding: 5px 10px;
  transition: background-color 0.3s ease;
  cursor: pointer !important;
}
.root-mobile-menu {
  width: 300px;
  position: fixed;
  right: 0;
  z-index: 2000;
  padding-top: 20px;
  font-weight: 500 !important;
  background: #94181c;
  height: calc(100vh);
  -webkit-transform: translateX(110%);
  -ms-transform: translateX(110%);
  transform: translateX(110%);
  -webkit-box-shadow: -10px 0 20px -10px rgba(0, 0, 0, 0.1);
  box-shadow: -10px 0 20px -10px rgba(0, 0, 0, 0.1);
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
  border-top-left-radius: 100px !important;
  border-bottom-left-radius: 100px !important;
  border-left: 10px solid #ff7700 !important;
}

.root-mobile-menu .root-mobile-menu-body {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  position: relative;
  padding: 0 20px 20px 20px;
  height: calc(100vh - 52px);
  padding-bottom: 150px;
}
.root-mobile-menu .arrow-collapse:hover {
  background: #f8f9fa;
}

.root-mobile-menu .arrow-collapse:before {
  font-size: 12px;
  z-index: 20;
  content: "\f078";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-180deg);
  transition: 0.3s all ease;
}

.root-mobile-menu .arrow-collapse.collapsed:before {
  transform: translate(-50%, -50%);
}
.root-mobile-menu > ul > li {
  display: block;
}

.root-mobile-menu .arrow-collapse {
  position: absolute;
  right: 0;
  top: 10px;
  z-index: 20;
  width: 36px;
  height: 36px;
  text-align: center;
  cursor: pointer;
  border-radius: 50%;
}

.root-mobile-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.root-mobile-menu a {
  padding: 10px 20px;
  display: block;
  position: relative;
  color: #ffffff;
  text-decoration: none !important;
}

.root-mobile-menu a:hover {
  color: #a3ff71;
}
