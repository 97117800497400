.chat-ai-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 250px;
  height: 100vh;
  overflow-y: auto;
  border: 1px solid #3f5558 !important;
  transition: 0.5s;
  z-index: 999;
}

.custom-list {
  background: transparent !important;
  border: 1px #d800b4 !important;
  color: cornsilk !important;
}
.custom-list li {
  border: 1px solid #30a6e5 !important;
}

.none-border {
  border-radius: none !important;
  border: none !important;
}
.messageBox {
  width: fit-content;
  height: 59px;
  width: 55%;
  
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2d2d2d;
  padding: 0 15px;
  border-radius: 10px;
  border: 1px solid rgb(63, 63, 63);

}
.messageBox:focus-within {
  border: 1px solid rgb(110, 110, 110);
}

.head-container h1 {
  margin-top: 5px;
  margin-bottom: 25%;
  font-size: 80px;
}
.left-side {
  float: left !important;
}
.head-container span {
  background: -webkit-linear-gradient(0deg, #d92c5a 0%, #30a6e5 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}
.AgriMentor {
  background: -webkit-linear-gradient(0deg, #ff0044 0%, #39ff1b 100%);
  -webkit-text-fill-color: transparent !important;
  -webkit-background-clip: text !important;
}
.ai-contaner {
  height: 100% !important;
}
.cb-c {
  max-width: 350px !important;
}
.tooltip {
  position: absolute;
  top: -40px;
  display: none;
  opacity: 0;
  color: white;
  font-size: 10px;
  text-wrap: nowrap;
  background-color: #000;
  padding: 6px 10px;
  border: 1px solid #3c3c3c;
  border-radius: 5px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.596);
  transition: all 0.3s;
}
#messageInput {
  width: 100% !important;
  height: 100% !important;
  background-color: transparent !important;
  outline: none;
  border: none;

  color: white;
}
.response-container p {
  justify-content: start !important;
}
/* .response-container {
  margin-left: 140px !important;
} */
/* .pt-container  {
   margin-left: 140px !important;
} */
.prompt-container p {
  justify-content: start !important;
}
.prompt-container p {
  text-align: start !important;
  margin-left: 30px !important;
  width: 70% !important;
  float: left !important;
  padding: 10px !important;
  background: #21262e;
  border-radius: 10px;
  border: 1px solid #8f7d8c !important;
  max-width: fit-content;
}

.response-container p {
  text-align: start !important;
  margin-left: 30px !important;
  width: 70% !important;
  float: left !important;
  padding: 10px !important;
  background: #0a141dad;
  border-radius: 10px;
  border: 1px solid #393939 !important;
  max-width: fit-content;
}
.response-container p {
  text-align: start !important;
  width: 80% !important;
  max-width: 800px !important;
}
.whitespace-per-line {
  white-space: pre-line !important;
  text-align: start !important;
}
.aichat-form {
  margin: auto 0 !important;
  justify-content: center !important;
  align-items: center !important;
}
#messageInput:focus ~ #sendButton svg path,
#messageInput:valid ~ #sendButton svg path {
  fill: #3c3c3c;
  stroke: white;
}

#sendButton {
  width: fit-content;
  height: 100%;
  background-color: transparent;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s;
}
#sendButton svg {
  height: 18px;
  transition: all 0.3s;
}
#sendButton svg path {
  transition: all 0.3s;
}
#sendButton:hover svg path {
  fill: #3c3c3c;
  stroke: white;
}
.custom-card-style {
  border: 1px solid #3f5558 !important;
}
.custom-card-style:hover {
  background: #282d31 !important;
  border: 1px solid #8f7d8c !important;
}




/* HTML: <div class="loader"></div> */
.resloader {
  width: 15px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l5 1s infinite linear alternate;
}
@keyframes l5 {
    0%  {box-shadow: 20px 0 #ffffff, -20px 0 rgba(141, 141, 141, 0.133);background: #6e6868 }
    33% {box-shadow: 20px 0 #767575, -20pxrgba(105, 98, 98, 0.133)002;background: rgba(114, 102, 102, 0.133)}
    66% {box-shadow: 20px 0 rgba(68, 68, 68, 0.133),-20px 0 #5e5d5d; background: rgba(77, 74, 74, 0.133)}
    100%{box-shadow: 20px 0 #0002,-20px 0 #413e3e; background: #1c1c1c }
}
.cntdiv {
    overflow-y: auto;
    height: 600px !important;
}