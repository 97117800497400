.file_manager .file a:hover .hover,
.file_manager .file .file-name small {
  display: block;
}
.file_manager .file {
  padding: 0 !important;
}

.file_manager .file .icon {
  text-align: center;
}

.file_manager .file {
  position: relative;
  border-radius: 0.55rem;
  overflow: hidden;
}

.file_manager .file .image,
.file_manager .file .icon {
  max-height: 180px;
  overflow: hidden;
  background-size: cover;
  background-position: top;
}

.file_manager .file .hover {
  position: absolute;
  right: 10px;
  top: 10px;
  display: none;
  transition: all 0.2s ease-in-out;
}

.file_manager .file a:hover .hover {
  transition: all 0.2s ease-in-out;
}

.file_manager .file .icon {
  padding: 15px 10px;
  display: table;
  width: 100%;
}

.file_manager .file .icon i {
  display: table-cell;
  font-size: 30px;
  vertical-align: middle;
  color: #777;
  line-height: 100px;
}

.file_manager .file .file-name {
  padding: 10px;
  border-top: 1px solid #f7f7f7;
}

.file_manager .file .file-name small .date {
  float: right;
}

.folder {
  padding: 20px;
  display: block;
  color: #ffffff !important;
}

@media only screen and (max-width: 992px) {
  .file_manager .nav-tabs {
    padding-left: 0;
    padding-right: 0;
  }
  .file_manager .nav-tabs {
    display: inline-block;
  }
}

.filecard {
  background: transparent !important;
  color: wheat !important;
  border: 1px solid ghostwhite;
  transition: 0.5s;
  margin-bottom: 30px;
  /* border: none !important; */
  border-radius: 0.55rem;
  position: relative;
  width: 100%;
  box-shadow: 0 1px 2px 0 rgba(168, 158, 158, 0.1) !important;
}

.filecard small {
  color: white !important;
  text-decoration: none !important;
}
.filecard p {
  color: white !important;
  text-decoration: none !important;
}
.reportbcuicon {
  width: 117px !important;
}
