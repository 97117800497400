@media (max-width: 600px) {
  .table-responsive .form-table,
  .table-responsive .form-thead,
  .table-responsive .form-tbody,
  .table-responsive .form-th,
  .table-responsive .form-td,
  .table-responsive .form-tr {
    display: block;
  }
  .table-responsive-form th,
  td,
  button {
    font-size: 13px !important;
  }
  .form_search {
    width: 100% !important;
  }

  .dsmnone {
    display: none !important;
  }
  .dsyes {
    display: block !important;
  }
  .service_cards {
    margin-bottom: 5% !important;
  }
  .service_cards p {
    font-size: 13px !important;
  }
  .service_cards .img_con {
    align-self: center !important;
    text-align: center !important;
  }
  .service_cards h4,
  .service_cards p {
    text-align: center !important;
  }
  .service_cards {
    width: 100% !important;
  }
  .aboutusimg {
    width: 100px !important;
  }
  .aboutusimgconn {
    text-align: center !important;
  }
  .logo_loader {
    transform: translate(-295%, -50%);
  }
  .stpill {
    display: none !important;
  }
  .profile-head {
    width: 100% !important;
    align-items: center !important;
    text-align: center !important;
  }
  .profile-head h5 {
    font-size: 30px;
    font-weight: 900;
  }
  .profile-head h6 {
    font-size: 17px;
    /* font-weight: 500; */
  }
  .profile-head p {
    font-size: 13px;
    /* font-weight: 500; */
  }
  .profile-img img {
    width: 200px !important;
    height: 200px !important;
    margin-bottom: -35px !important;
  }
  .card-header-tabs .nav-link {
    font-size: 11px;
  }

  .card-header-tabs li {
    display: flex !important;
    padding: -40px;
  }
  .icoo {
    margin-top: 40px !important;
  }
  .uploadpro {
    width: 80% !important;
  }
  .mobile-v-stl {
    background-color: rgb(15, 1, 27) !important;
    padding: -80px !important;
  }
  .pll {
    padding-left: 2% !important;
  }
  .monb {
    font-size: 30px !important;
  }
  .mobile- {
    border: 1px solid ghostwhite !important;
  }
}
